import requestWithLoading from '@/utils/requestWithLoading';
export function addQuestionnaireRecord(data) {
  return requestWithLoading({
    url: '/wx/questionnaire/add',
    method: 'post',
    data: data
  });
}
export function getQuestionnaireRecord() {
  return requestWithLoading({
    url: '/wx/questionnaire/get',
    method: 'get'
  });
}