import _defineProperty from "/Users/jackh/Desktop/2024-web/20240305/global-charge-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.regexp.to-string.js";
export default _defineProperty({
  name: "ProductItem",
  props: {
    type: String,
    productInfo: Object,
    disabled: Boolean,
    active: Boolean
  },
  data: function data() {
    return {
      isFirst: "首次优惠",
      isRechargeCard: "充值卡",
      isBatch: false
    };
  },
  created: function created() {
    console.log(isHot(), " wei0-----------");
  },
  computed: {
    isFirstRechargeUser: function isFirstRechargeUser() {
      return this.$store.state.isFirstRechargeUser;
    }
  },
  methods: {
    // 展示什么提示页
    showTips: function showTips() {
      // 1 是否属于卡密 (充值卡)
      if (this.productInfo.pin && !this.isBatch) {
        console.log("111", this.isRechargeCard);
        console.log("111this.productInfo.pin && !isBatch", this.productInfo.pin && !this.isBatch);
        return this.isRechargeCard;
      } else if (this.productInfo.firstDiscount && this.isFirstRechargeUser && !this.productInfo.pin && !this.isBatch) {
        // 2 是否首次优惠
        console.log("2", this.isFirst);
        return this.isFirst;
      } else if (this.productInfo.hot) {
        // 3 是否热销
        console.log("333", this.productInfo.hotTips);
        return this.productInfo.hotTips;
      }
    },
    //是否热销
    isHot: function isHot() {
      // 批冲
      if (this.productInfo.pin && !this.isBatch) {
        return false;
      }

      // 首充
      if (this.productInfo.firstDiscount && this.isFirstRechargeUser && !this.productInfo.pin && !this.isBatch) {
        return false;
      }
      if (this.productInfo.hot) {
        return true;
      }
    }
  }
}, "created", function created() {
  /**
   * 如果为true的话就是批充
   * 则不展示卡密
   * */
  var reg = RegExp(/batch\/recharge/);
  this.isBatch = reg.test(this.$route.path);
  console.log("this.productInfo   =====?", this.productInfo);
  // console.log(this.isBatch,"正则判断true为批充，false为单充")
  // console.log(this.isBatch,"当前是否是批充。。")
  // console.log(this.productInfo.pin);
  // console.log("当前产品信息====>",this.productInfo)
});