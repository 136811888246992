import _defineProperty from "/Users/jackh/Desktop/2024-web/20240305/global-charge-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/popup/style";
import _Popup from "vant/es/popup";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import ZchIcon from "@/components/ZchIcon";
import { isIOS } from "@/utils/index.js";
export default {
  name: "ZchCarrierList",
  components: _defineProperty({
    ZchIcon: ZchIcon
  }, _Popup.name, _Popup),
  props: {
    carrierVisible: Boolean,
    carrierList: Array,
    selectedCarrierName: String,
    activeTab: String,
    getProductList: Function,
    removeProductListActiveIndex: Function
  },
  data: function data() {
    return {
      isIOS: isIOS
    };
  },
  methods: {
    skipToImageTextDetailPage: function skipToImageTextDetailPage() {
      // this.pinStudyNewsId = 10;
      // 安卓 id = 117 IOS id = 118
      var pinStudyNewsId;
      if (isIOS) {
        pinStudyNewsId = 118;
      } else {
        pinStudyNewsId = 117;
      }
      this.$router.push({
        path: "/home/imageTextAndShowPage",
        query: {
          llStudyNewsId: pinStudyNewsId
        }
      });
    }
  },
  computed: {
    _carrierVisible: {
      set: function set(value) {
        this.$emit("close", value);
      },
      get: function get() {
        return this.carrierVisible;
      }
    }
  }
};