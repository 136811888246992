import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.string.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("title", [_vm._v("问卷调查")]), _c("van-dialog", {
    attrs: {
      title: "提示",
      "show-cancel-button": "",
      "cancel-button-text": "关闭",
      "confirm-button-text": "赚积分",
      confirmButtonColor: "#2839FE"
    },
    on: {
      cancel: _vm.closePage,
      confirm: _vm.earnPoints
    },
    model: {
      value: _vm.haveFilledInAlert,
      callback: function callback($$v) {
        _vm.haveFilledInAlert = $$v;
      },
      expression: "haveFilledInAlert"
    }
  }, [_c("div", {
    staticStyle: {
      "text-align": "center",
      margin: "20px 0",
      "font-size": "16px"
    }
  }, [_vm._v(" 您已经填写过调查问卷了！ ")])]), _c("van-dialog", {
    attrs: {
      title: "提交成功",
      "confirm-button-text": "确定",
      confirmButtonColor: "#2839FE"
    },
    on: {
      confirm: _vm.closePage
    },
    model: {
      value: _vm.haveFilledInAlert2,
      callback: function callback($$v) {
        _vm.haveFilledInAlert2 = $$v;
      },
      expression: "haveFilledInAlert2"
    }
  }, [_c("div", {
    staticStyle: {
      "text-align": "center",
      margin: "20px 0",
      "font-size": "16px"
    }
  }, [_vm._v(" 200积分已赠送，感谢您的参与 ")])]), _c("img", {
    attrs: {
      src: require("../../assets/home/QuestionnaireSurvey.jpg"),
      alt: "",
      width: "100%"
    }
  }), _c("div", {
    staticClass: "question-card"
  }, [_vm._m(0), _vm._l(_vm.travelTimesOptions, function (option, index) {
    return _c("div", {
      key: index,
      on: {
        click: _vm.verifyIintegrity
      }
    }, [_c("label", {
      staticClass: "custom-radio"
    }, [_c("input", {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.formData.travel_times,
        expression: "formData.travel_times"
      }],
      attrs: {
        type: "radio",
        name: "travel_times"
      },
      domProps: {
        value: option,
        checked: _vm._q(_vm.formData.travel_times, option)
      },
      on: {
        change: function change($event) {
          return _vm.$set(_vm.formData, "travel_times", option);
        }
      }
    }), _c("span", {
      staticClass: "radio-square"
    }), _vm._v(" " + _vm._s(option) + " ")])]);
  })], 2), _c("div", {
    staticClass: "question-card"
  }, [_vm._m(1), _vm._l(_vm.travelPurposeOptions, function (option, index) {
    return _c("div", {
      key: index,
      on: {
        click: _vm.verifyIintegrity
      }
    }, [_c("label", {
      staticClass: "custom-radio"
    }, [_c("input", {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.formData.travel_purpose,
        expression: "formData.travel_purpose"
      }],
      attrs: {
        type: "radio",
        name: "travel_purpose"
      },
      domProps: {
        value: option,
        checked: _vm._q(_vm.formData.travel_purpose, option)
      },
      on: {
        change: function change($event) {
          return _vm.$set(_vm.formData, "travel_purpose", option);
        }
      }
    }), _c("span", {
      staticClass: "radio-square"
    }), _vm._v(" " + _vm._s(option) + " ")])]);
  })], 2), _c("div", {
    staticClass: "question-card"
  }, [_vm._m(2), _vm._l(_vm.travelDurationOptions, function (option, index) {
    return _c("div", {
      key: index,
      on: {
        click: _vm.verifyIintegrity
      }
    }, [_c("label", {
      staticClass: "custom-radio"
    }, [_c("input", {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.formData.travel_duration,
        expression: "formData.travel_duration"
      }],
      attrs: {
        type: "radio",
        name: "travel_duration"
      },
      domProps: {
        value: option,
        checked: _vm._q(_vm.formData.travel_duration, option)
      },
      on: {
        change: function change($event) {
          return _vm.$set(_vm.formData, "travel_duration", option);
        }
      }
    }), _c("span", {
      staticClass: "radio-square"
    }), _vm._v(" " + _vm._s(option) + " ")])]);
  })], 2), _c("div", {
    staticClass: "question-card"
  }, [_vm._m(3), _vm._l(_vm.platformSourceOptions, function (option, index) {
    return _c("div", {
      key: index,
      on: {
        click: _vm.verifyIintegrity
      }
    }, [_c("label", {
      staticClass: "custom-radio"
    }, [_c("input", {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.formData.platform_source,
        expression: "formData.platform_source"
      }],
      attrs: {
        type: "radio",
        name: "platform_source"
      },
      domProps: {
        value: option,
        checked: _vm._q(_vm.formData.platform_source, option)
      },
      on: {
        change: function change($event) {
          return _vm.$set(_vm.formData, "platform_source", option);
        }
      }
    }), _c("span", {
      staticClass: "radio-square"
    }), _vm._v(" " + _vm._s(option) + " ")])]);
  })], 2), _c("div", {
    staticClass: "question-card"
  }, [_vm._m(4), _vm._l(_vm.recommendOptions, function (option, index) {
    return _c("div", {
      key: index,
      on: {
        click: _vm.verifyIintegrity
      }
    }, [_c("label", {
      staticClass: "custom-radio"
    }, [_c("input", {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.formData.recommend,
        expression: "formData.recommend"
      }],
      attrs: {
        type: "radio",
        name: "recommend"
      },
      domProps: {
        value: option,
        checked: _vm._q(_vm.formData.recommend, option)
      },
      on: {
        change: [function ($event) {
          return _vm.$set(_vm.formData, "recommend", option);
        }, _vm.handleRecommendChange]
      }
    }), _c("span", {
      staticClass: "radio-square"
    }), _vm._v(" " + _vm._s(option) + " ")])]);
  }), _c("div", {
    staticClass: "input-reason"
  }, [_c("label", [_vm._v("原因：")]), _c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.reason,
      expression: "formData.reason"
    }],
    ref: "reasonInput",
    attrs: {
      type: "text",
      placeholder: "请输入原因"
    },
    domProps: {
      value: _vm.formData.reason
    },
    on: {
      input: function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "reason", $event.target.value);
      }
    }
  })])], 2), _c("div", {
    staticClass: "question-card"
  }, [_vm._m(5), _vm._l(_vm.additionalServicesOptions, function (option, index) {
    return _c("div", {
      key: index,
      on: {
        click: _vm.verifyIintegrity
      }
    }, [_c("label", {
      staticClass: "custom-checkbox"
    }, [_c("input", {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.formData.additional_services,
        expression: "formData.additional_services"
      }],
      attrs: {
        type: "checkbox"
      },
      domProps: {
        value: option,
        checked: Array.isArray(_vm.formData.additional_services) ? _vm._i(_vm.formData.additional_services, option) > -1 : _vm.formData.additional_services
      },
      on: {
        change: [function ($event) {
          var $$a = _vm.formData.additional_services,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = option,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(_vm.formData, "additional_services", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(_vm.formData, "additional_services", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(_vm.formData, "additional_services", $$c);
          }
        }, function ($event) {
          return _vm.handleOtherOptionChange(option);
        }]
      }
    }), _c("span", {
      staticClass: "checkbox-square"
    }), _vm._v(" " + _vm._s(option) + " ")])]);
  }), _vm.formData.additional_services.includes("其他") ? _c("div", {
    staticClass: "input-reason"
  }, [_c("label", [_vm._v("其他需求：")]), _c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.other_reason,
      expression: "formData.other_reason"
    }],
    ref: "otherReasonInput",
    attrs: {
      type: "text",
      placeholder: "请输入其他的具体服务需求"
    },
    domProps: {
      value: _vm.formData.other_reason
    },
    on: {
      input: function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "other_reason", $event.target.value);
      }
    }
  })]) : _vm._e()], 2), _vm.isShowButton ? _c("div", {
    on: {
      click: _vm.submitQuestionnaire
    }
  }, [_c("wx-open-subscribe", {
    staticStyle: {
      width: "400px",
      height: "70px",
      background: "red",
      position: "absolute",
      opacity: "0"
    },
    attrs: {
      template: _vm.subTemplateId,
      id: "subscribe-btn"
    },
    on: {
      success: _vm.success2,
      error: _vm.subError2
    }
  }, [_c("script", {
    attrs: {
      type: "text/wxtag-template"
    }
  }, [_vm._v(' <style> .btn2 { width: 45px; height: 50px; border: none; padding: 15px 0; color: red; } </style> <button class="btn2">立即参与</button> ')])])], 1) : _vm._e(), _c("div", {
    staticClass: "submit-button",
    on: {
      click: _vm.submitQuestionnaire
    }
  }, [_vm._v("提交")])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("p", [_c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("*")]), _vm._v("1. 这是您第几次出国？")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("p", [_c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("*")]), _vm._v("2. 您出国的目的是?")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("p", [_c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("*")]), _vm._v("3. 此次出国停留时长：")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("p", [_c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("*")]), _vm._v("4. 您是通过什么渠道了解到我们游全球海外话费充值平台？ ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("p", [_c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("*")]), _vm._v("5. 您愿意主动将游全球推荐给其他人吗：")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("p", [_c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("*")]), _vm._v("6. 希望游全球除话费充值外还能提供哪些其他服务？（可多选） ")]);
}];
render._withStripped = true;
export { render, staticRenderFns };