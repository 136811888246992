import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [!_vm.llStudyNewsId ? _c("div", {
    staticClass: "point"
  }, [_c("zch-icon", {
    attrs: {
      icon: "emptyTip"
    }
  }), _vm._v(" 提醒：暂无直充产品，拨号订购请确保话费充足 ")], 1) : _vm.llStudyNewsId ? _c("div", {
    staticClass: "point"
  }, [_c("zch-icon", {
    attrs: {
      icon: "emptyTip"
    }
  }), _vm._v(" 提醒：暂无流量产品，如需充值请参考以下教程 ")], 1) : _vm._e(), _vm.exchangeProductList ? _c("div", [_vm.exchangeProductList.length > 0 ? _c("div", _vm._l(_vm.exchangeProductList, function (item, index) {
    return _c("div", {
      key: item.id
    }, [item.valid && (item.smsCode || item.smsPhone || item.dialCode) ? _c("div", {
      staticClass: "item-card"
    }, [_c("div", {
      staticClass: "left"
    }, [_c("div", {
      staticClass: "left-top"
    }, [_c("div", {
      staticClass: "title"
    }, [_vm._v(_vm._s(item.name))]), _c("div", {
      staticClass: "unitValidity"
    }, [_vm._v(" " + _vm._s(item.tips > -1 ? item.tips + _vm.unitValidityEnum[item.unitValidity] : "用完截至") + " ")])]), _c("div", {
      staticClass: "desc"
    }, [_c("span", {
      staticStyle: {
        color: "red"
      }
    }, [_vm._v("扣除话费" + _vm._s(item.originalPrice) + _vm._s(item.currency))]), _vm._v(" ," + _vm._s(item.info ? item.info : "暂无描述") + " ")])]), _c("div", {
      staticClass: "right"
    }, [item.smsCode ? _c("div", {
      staticClass: "box-content"
    }, [_c("a", {
      staticClass: "box",
      attrs: {
        href: "#"
      },
      on: {
        click: function click($event) {
          return _vm.preGetSmsLink(item, $event);
        }
      }
    }, [_c("i", {
      staticClass: "iconfont icon-xiaoxi",
      staticStyle: {
        "font-size": "24px",
        "vertical-align": "-14px"
      }
    })]), _c("div", {
      staticStyle: {
        "margin-top": "3px"
      }
    }, [_vm._v("短信")])]) : _vm._e(), item.dialCode ? _c("div", {
      staticClass: "box-content",
      on: {
        click: function click($event) {
          return _vm.premakePhoneCall(item.dialCode);
        }
      }
    }, [_vm._m(0, true), _c("div", {
      staticStyle: {
        "margin-top": "3px"
      }
    }, [_vm._v("拨号")])]) : _vm._e()])]) : _vm._e()]);
  }), 0) : _vm._e(), _c("div", [_c("ImageTextAndArticleDetail", {
    key: _vm.llStudyNewsId,
    attrs: {
      llStudyNewsId: _vm.llStudyNewsId,
      llStudyNewsList: _vm.llStudyNewsList,
      name: _vm.name
    }
  })], 1)]) : _c("div", {
    staticStyle: {
      "text-align": "center",
      "line-height": "30px",
      "font-size": "16px"
    }
  }, [_vm._v(" 暂无数据 ")])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "box"
  }, [_c("i", {
    staticClass: "iconfont icon-24gf-telephone",
    staticStyle: {
      "font-size": "20px",
      "vertical-align": "-12px"
    }
  })]);
}];
render._withStripped = true;
export { render, staticRenderFns };